import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout/'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer/'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { Link } from 'gatsby'

import '../scss/starter.scss'

const Post = ({ data }) => {
  const { slug, settings, company, navigation, footer } = data.contentfulPage

  const { postSlug, body, publishDate, title, seo, image } = data.contentfulPost

  const { edges } = data.allContentfulPost
  let currentNode = edges.filter((edge) => edge.node.slug === postSlug)

  const postImage = image && getImage(image.file)

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="blog-paragraph">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="blog-header">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="blog-header">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="blog-header">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="blog-header">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className="blog-header">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className="blog-header">{children}</h6>
      ),
      [BLOCKS.QUOTE]: (node, children) => (
        <div className="blog-blockquote">
          <blockquote>{children}</blockquote>
        </div>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <ol className="blog-ordered-list">{children}</ol>
      ),
      [BLOCKS.UL_LIST]: (node, children) => {
        return <ul className="blog-unordered-list">{children}</ul>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <li className="blog-list-item">{children}</li>
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <div className="justify-content-center d-flex mb-4">
            <img
              loading="lazy"
              src={node.data.target.file.url}
              alt={`${node.data.target.title}`}
            />
          </div>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {},
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri.charAt(0) === '/') {
          return (
            <Link to={node.data.uri} className="rising_link text-black">
              {node.content[0].value}
            </Link>
          )
        } else {
          return (
            <a
              href={node.data.uri}
              className="rising_link text-black"
              target="_blank"
            >
              {node.content[0].value}
            </a>
          )
        }
      },
    },
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  const post = renderRichText(body, options)
  const [scroll, setScroll] = useState(false)

  return (
    <Layout slug={slug} settings={settings} seo={seo} scroll={scroll}>
      <Navigation
        company={company}
        settings={settings}
        navigation={navigation}
        slug={slug}
        setScroll={setScroll}
      />

      <section className="slice-lg bg-iac-gray-blue">
        <div className="container py-5">
          <div className="row text-center justify-content-center py-5">
            <div className="col-sm-12">
              <h1 className="headline text-white">{title}</h1>
              <p className="text-white">{publishDate}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container my-5">
        <div className="row">
          {image ? (
            <div className="col-sm-2">
              <GatsbyImage
                alt={image.alt}
                image={postImage}
                className="img-fluid"
              />
            </div>
          ) : null}
          <div className={image ? 'col-sm-10' : 'col-sm-10 offset-sm-1'}>
            {post}
          </div>
        </div>
      </section>
      <Footer props={footer} company={company} />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      body {
        raw
      }
      excerpt {
        childMarkdownRemark {
          html
        }
      }
      publishDate(formatString: "MMM DD YYYY")
      postSlug: slug
      title
      seo {
        pageTitle
        description
        robots
        twitterTitle
        twitterCreator

        ogTitle
      }
      image {
        file {
          url
          gatsbyImageData
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    allContentfulPost {
      edges {
        previous {
          slug
          publishDate(formatString: "MMMM DD YYYY")
          title
        }
        next {
          slug
          publishDate(formatString: "MMMM DD YYYY")
          title
        }
        node {
          slug
          title
          publishDate(formatString: "MMMM DD YYYY")
        }
      }
    }
    contentfulPage(slug: { eq: "/newsroom" }) {
      name
      slug
      settings {
        isBlogPost
      }
      seo {
        pageTitle
        description
        robots
        twitterTitle
        twitterCreator

        ogTitle

        ogImageWidth
        ogImageHeight
      }

      company {
        id
        name
      }
      navigation {
        id
        name
        logo {
          file {
            url
            gatsbyImageData(formats: WEBP, width: 200)
          }
          alt
        }
        sections {
          id
          name
          showInTopNav
          page {
            name
            slug
            navLabel
            id
          }
          subPages {
            name
            navLabel
            id
            slug
          }
        }
      }
      modules {
        id
        name
        sliceSize
        container
        extraClasses
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        module {
          ... on ContentfulHero {
            id
            name
            type
            backgroundPosition
            bodyText {
              raw
            }
            headline
            button {
              buttonColor
              buttonText
              destination
              id
            }
            image {
              file {
                url
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            slider {
              name
              hasNavArrows
              hasPagination
              slideDuration
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              slides {
                id
                bodyText {
                  raw
                }
                image {
                  file {
                    url
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                button {
                  buttonColor
                  buttonText
                  name
                  destination
                }
                headline
                name
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulSplitContentModule {
            id
            name
            splitContentRow {
              bodyText {
                raw
              }
              button {
                buttonColor
                buttonText
                destination
                id
              }
              headline
              id
              imageOn
              isImageRounded
              image {
                alt
                file {
                  gatsbyImageData(layout: FULL_WIDTH)
                  description
                  url
                  publicUrl
                }
              }
              hasCenterElement
              centerElementImage {
                alt
                file {
                  gatsbyImageData(width: 160)
                  description
                  url
                  publicUrl
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulFullWidthModule {
            id
            name
            headline
            backgroundPosition
            hasOverlay
            bodyText {
              raw
            }
            button {
              buttonColor
              buttonText
              destination
              id
            }
            image {
              alt
              file {
                gatsbyImageData
                url
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulTeamModule {
            id
            name
            bodyText {
              raw
            }
            headline
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            teamMembers {
              id
              name
              title
              photo {
                alt
                file {
                  url
                  gatsbyImageData
                }
              }
            }
          }
          ... on ContentfulImageGrid {
            id
            name
            bodyText {
              raw
            }
            headline
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            media {
              ... on ContentfulColor {
                id
                name
                hexValue
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              ... on ContentfulImage {
                id
                name
                alt
                file {
                  url
                  gatsbyImageData
                }
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulCardModule {
            id
            name
            headline
            bodyText {
              raw
            }
            negativeTopMargin
            textAlign
            cards {
              bodyText {
                raw
              }
              cardType
              cardWidth
              headline
              id
              image {
                alt
                id
                file {
                  gatsbyImageData
                  url
                }
              }
              button {
                buttonColor
                buttonText
                destination
                id
              }
              tileLinkDestination {
                slug
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }

      footer {
        contactBlock {
          raw
        }
        copyright
        footerNavPages {
          navLabel
          slug
          id
        }
        socialLinks {
          name
          link
          platform
          id
        }
        logo {
          alt
          file {
            gatsbyImageData(height: 24)
          }
        }
        legalPages {
          navLabel
          slug
          id
        }
      }
    }
  }
`

export default Post
